import * as React from "react"
import {Pagination, Toolbar, IconButton, Container, CardActionArea, Card, CardHeader, Typography, CardContent, CardMedia, AppBar } from '@mui/material';
import { Link, navigate } from "gatsby";
//import { Appbar } from "../components";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { maxHeight } from "@mui/system";
import {StaticImage} from 'gatsby-plugin-image';


const MainPage = () => {
    const height = 300;

    return(
      <Container>
        <Typography variant="h2" style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}}>Welcome to Braxton & Josh Made Simple's Online Shop</Typography>
<Typography>Please select a Complete & Balanced Adult Dog recipe from the options below. You will be able to see 
  recipe components and prices customized to the quantity required for your dog on the next page.
</Typography>
        <Card sx={{ height: 300}}>
            <CardActionArea onClick={() => navigate('/selectWeight?recipeType=chicken', {state:{"recipe":"chicken"}})}>        
              <CardContent>
                <StaticImage src="https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/chicken.png"
                      layout="fixed"
                      width={300}
                      height={200}/>
                <Typography gutterBottom variant="h5" component="div">
                  Chicken
                </Typography>
                <Typography variant="body2" color="text.secondary">
                      Starting at $2.93/day
                </Typography>
              </CardContent>
            </CardActionArea>
        </Card>
        <Card sx={{ height: 300}}>
            <CardActionArea onClick={() => navigate('/selectWeight?recipeType=beef', {state:{"recipe":"beef"}})}>        
              <CardContent>
              <StaticImage src="https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/beef.png"
                      layout="fixed"
                      width={300}
                      height={200}/>
                <Typography gutterBottom variant="h5" component="div">
                  Beef
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Starting at $2.25/day
                </Typography>
              </CardContent>
            </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275 }}>
            <CardActionArea onClick={() => navigate('/selectWeight?recipeType=bison', {state:{"recipe":"bison"}})}>        
              <CardContent>
              <StaticImage
          src="https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/bison.png"
          layout="fixed"
          width={300}
          height={200}
        />
                <Typography gutterBottom variant="h5" component="div">
                  Bison
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Starting at $3.91/day
                </Typography>
              </CardContent>
            </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275 }}>
            <CardActionArea onClick={() => navigate('/selectWeight?recipeType=chicken_beef', {state:{"recipe":"chicken_beef"}})}>        
              <CardContent>
            <StaticImage
          src="https://raw.githubusercontent.com/dhruvv/Football-Auction-System/6a8d589d0e27bdb71ef352447e5f263f79601284/chicken-beef.png"
          layout="fixed"
          width={300}
          height={200}
        />
                <Typography gutterBottom variant="h5" component="div">
                  Chicken-Beef
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Starting at $2.93/day
                </Typography>
              </CardContent>
            </CardActionArea>
        </Card>
      </Container>
    )
  }

  const IndexPage = (event, value) => {
    const handleChange = () => {
      if (value == 2) {
        navigate('/')
      }
    }
    return (
      <main>
        <MainPage></MainPage>
        <AppBar position="sticky" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <IconButton href="http://braxtonsnaturaldogfood.com/">
              <ChevronLeftIcon>  </ChevronLeftIcon>
            </IconButton>
              <Pagination count={1} onChange={handleChange} />
          </Toolbar>
        </AppBar>
      </main>
    )
  }
  
  
  export default IndexPage;